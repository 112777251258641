const appKey = 'HolyPaymentUI';
import { Guid } from 'guid-typescript';
import * as Moment from 'moment';
import * as LoggingService from './loggingService';

export interface ISessionState {
	customerUrlString: string | null;
	paymentAmount: number | null;
	language: string | null;
	returnUrl: string | null;
	expirationDate: string;
	transactionId: string | null;
	errorCode: string | null;
	isPaymentAmountEditable: boolean;
}

const getSessionKey = (sessionKey: Guid): string => appKey + sessionKey.toString().toLowerCase();

export const setSessionStore = (sessionKey: Guid, data: ISessionState): void => {
	sessionStorage.setItem(getSessionKey(sessionKey), JSON.stringify(data));
};

export const getSessionStore = (sessionKey: Guid): ISessionState | null => {
	const sessionItemString: string | null = sessionStorage.getItem(getSessionKey(sessionKey));
	let sessionItemObject: ISessionState = {} as ISessionState;
	if (!sessionItemString) {
		return null;
	}

	sessionItemObject = JSON.parse(sessionItemString);

	return sessionItemObject;
};

export const createSessionStore = (sessionKey: Guid): ISessionState => {
	const sessionItemObject: ISessionState = {} as ISessionState;

	setSessionStore(sessionKey, sessionItemObject);

	return sessionItemObject;
};

const hasDatePassed = (date: string): boolean => {
	const now = Moment();
	const dateToCheck = Moment(date);
	if (dateToCheck.diff(now) <= 0) {
		return true;
	}

	return false;
};

export const isSessionStoreExpired = (sessionKey: Guid): boolean => {
	const sessionStore: ISessionState | null = getSessionStore(sessionKey);
	if (sessionStore) {
		return hasDatePassed(sessionStore.expirationDate);
	}

	return true;
};

export const updateSessionStoreFromUrlAndSetExpiration = (sessionKey: Guid, sessionStore: ISessionState): ISessionState => {
	const sessionStoreNew = {} as ISessionState;

	const customerStringFromUrl = getCustomerStringFromUrl();
	const paymentAmountFromUrl = getInitialPaymentAmountFromUrl();
	const languageFromUrl = getLanguageFromUrl();
	const isPaymentAmountReadOnly = getPaymentAmountReadOnlyFromUrl();

	sessionStoreNew.customerUrlString = customerStringFromUrl ? customerStringFromUrl : sessionStore.customerUrlString;
	sessionStoreNew.paymentAmount = (paymentAmountFromUrl ? paymentAmountFromUrl : sessionStore.paymentAmount) || 0;
	sessionStoreNew.language = languageFromUrl ? languageFromUrl : sessionStore.language;
	sessionStoreNew.returnUrl = sessionStore.returnUrl ? sessionStore.returnUrl : getReferrerUrl();
	sessionStoreNew.transactionId = sessionStore.transactionId;
	sessionStoreNew.errorCode = sessionStore.errorCode;
	const isPaymentAmountEditableSessionValue = sessionStore.isPaymentAmountEditable === undefined ? true : sessionStore.isPaymentAmountEditable;
	sessionStoreNew.isPaymentAmountEditable = isPaymentAmountReadOnly ? !isPaymentAmountReadOnly : isPaymentAmountEditableSessionValue;

	const expDate = new Date();
	expDate.setMinutes(expDate.getMinutes() + 30);
	sessionStoreNew.expirationDate = expDate.toISOString();

	setSessionStore(sessionKey, sessionStoreNew);

	return sessionStoreNew;
};

export const setTransactionId = (sessionKey: Guid, transactionId: string) => {
	const sessionStoreNew: ISessionState | null = getSessionStore(sessionKey);
	if (sessionStoreNew) {
		sessionStoreNew.transactionId = transactionId;
		setSessionStore(sessionKey, sessionStoreNew);
	}
};

const getReferrerUrl = () => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.has('redirectUrl')) {
		return urlSearchParams.get('redirectUrl');
	}
	if (urlSearchParams.has('r')) {
		return urlSearchParams.get('r');
	}
	return document.referrer;
};

export const getCustomerStringFromUrl = (): string | null => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.has('data')) {
		return urlSearchParams.get('data');
	}
	if (urlSearchParams.has('d')) {
		return urlSearchParams.get('d');
	}
	return null;
};

export const getInitialPaymentAmountFromUrl = (): number | null => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.has('amount')) {
		return urlSearchParams.get('amount') as number | null;
	}
	if (urlSearchParams.has('a')) {
		return urlSearchParams.get('a') as number | null;
	}
	return null;
};

export const getLanguageFromUrl = (): string | null => {
	const urlSearchParams = new URLSearchParams(window.location.search);

	// Url should always override the session.
	if (urlSearchParams.has('lang')) {
		return urlSearchParams.get('lang');
	}
	if (urlSearchParams.has('l')) {
		return urlSearchParams.get('l');
	}

	return null;
};

export const getPaymentAmountReadOnlyFromUrl = (): boolean => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.has('ar')) {
		return urlSearchParams.get('ar') === '1';
	}
	return false;
};

export const getCorrelationIdFromUrlOrDefault = (): Guid => {
	let sessionKey: Guid | null = null;
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.has('correlationId')) {
		sessionKey = urlSearchParams.get('correlationId') as Guid | null;
	}
	if (urlSearchParams.has('c')) {
		sessionKey = urlSearchParams.get('c') as Guid | null;
	}

	if (sessionKey == null) {
		sessionKey = Guid.create();
		LoggingService.logWarning(sessionKey, 'Could not find a sessionKey, generating a new one');
	}
	return sessionKey as Guid;
};

export const getTysonStringFromUrl = (): string | null => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.has('paymentInfo')) {
		return urlSearchParams.get('paymentInfo');
	}
	return null;
};
