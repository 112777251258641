import { Dispatch, Action } from 'redux';
import { IState } from '../models';
import * as NavigationStateService from '../services/navigationStateService';
import { tryHandleSessionExpiration, Actions } from './PageActions';

export const setPaymentAmount = (paymentAmount: number) => (dispatch: Dispatch<Action>, getState: () => { page: IState }) => {
	const props: IState = getState().page;
	if (tryHandleSessionExpiration(dispatch, props.sessionKey, props.isSuccess, props.isError)) {
		return;
	}

	dispatch({
		type: Actions.setPaymentAmount,
		data: { paymentAmount },
	});

	const sessionStore: NavigationStateService.ISessionState | null = NavigationStateService.getSessionStore(props.sessionKey);
	if (sessionStore) {
		sessionStore.paymentAmount = paymentAmount;
		NavigationStateService.setSessionStore(props.sessionKey, sessionStore);
	}
};
