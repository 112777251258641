import React, { useState } from 'react';
import { TextInput, TextInputProps, MaskedTextInput } from '@eftours/labs.ui-components';
import { Contracts } from '@eftours/tokenization-client-library';

interface IMaskedTextInputProps extends TextInputProps {
	mask: 'DateOfBirth' | 'ZipCode' | 'Phone' | Array<string | RegExp>;
	locale?: 'en-US' | 'en-CA' | 'fr-CA';
	showGuide?: boolean;
}

interface IValidationProps {
	validation: Contracts.ValidationIssue | undefined;
}

function IsMaskedInputProps(props: TextInputProps | IMaskedTextInputProps): props is IMaskedTextInputProps {
	return (props as any).mask !== undefined;
}

const hasAnyKeys = (issue: Contracts.ValidationIssue) => {
	return Object.keys(issue).length !== 0;
};

export const ValidTextInput: React.FunctionComponent<(TextInputProps | IMaskedTextInputProps) & IValidationProps> = ({ validation, ...rest }) => {
	const [hasFocus, setHasFocus] = useState(false);
	const [hasBlurred, setHasBlurred] = useState(false);

	const getValidationAttr = () => {
		// Only show that a field is invalid if it has been blurred
		// is not currently selected, and has a non-empty validation object
		// i.e. it has errors
		// Also the component library needs a truthy value to render the error mark
		// but since we don't want any text here, we are using an `space` in a string
		return {
			error: validation && hasAnyKeys(validation) && hasBlurred && !hasFocus ? ' ' : undefined,
		};
	};

	const onBlurred = (_value: string) => {
		setHasBlurred(true);
		setHasFocus(false);
		return {};
	};

	if (IsMaskedInputProps(rest)) {
		return (
			<>
				<React.Fragment>
					<MaskedTextInput
						onFocus={() => setHasFocus(true)}
						onBlur={(e) => onBlurred(e.target.value)}
						// need to fix locales in component lib to remove `any`.
						{...getValidationAttr()}
						{...(rest as any)}
					/>
				</React.Fragment>
			</>
		);
	} else {
		return (
			<>
				<React.Fragment>
					<TextInput onFocus={() => setHasFocus(true)} onBlur={(e) => onBlurred(e.target.value)} {...getValidationAttr()} {...rest} />
				</React.Fragment>
			</>
		);
	}
};
