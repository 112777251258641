import { Dispatch, Action } from 'redux';
import { IState } from '../models';
import { postMakePayment } from '../services/paymentsService';
import * as NavigationStateService from '../services/navigationStateService';
import { Actions, tryHandleSessionExpiration } from './PageActions';
import { handleMakePaymentError } from './HandleMakePaymentErrorAction';

export const makePayment = async (dispatch: Dispatch<Action>, getState: () => { page: IState }) => {
	dispatch({ type: Actions.enableSpinner });

	const props: IState = getState().page;

	const { paymentAmount, paymentMethod } = props;
	if (paymentMethod == null) {
		return;
	}

	const sessionStore: NavigationStateService.ISessionState | null = NavigationStateService.getSessionStore(props.sessionKey);
	if (tryHandleSessionExpiration(dispatch, props.sessionKey, props.isSuccess, props.isError)) {
		return;
	}

	// Last Chance validation before submission
	if (paymentAmount < 0) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: 'Payment amount may be missing or is less than or equal to 0 on submission',
			errorCode: 'amountLessOrEqualToZero',
		});
		return;
	}

	if (!sessionStore) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: 'Unable to submit final payment. Session storage missing.',
			errorCode: 'paymentMethodMissing',
		});
		return;
	}

	if (!sessionStore.customerUrlString) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: "Unable to submit final payment. Session storage's Customer URL missing.",
			errorCode: 'paymentMethodMissing',
		});
		return;
	}

	const result = await postMakePayment(sessionStore.customerUrlString, paymentMethod, paymentAmount, props.sessionKey, props.usingAlb, props.albRoute);
	if (result && result.isSuccessful) {
		dispatch({ type: Actions.disableSpinner });
		NavigationStateService.setTransactionId(props.sessionKey, result.transactionId);
		dispatch({ type: Actions.successfulPayment, data: { transactionId: result.transactionId } });
	} else {
		handleMakePaymentError(dispatch, props, result);
	}
};
