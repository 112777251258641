import { Dispatch, Action } from 'redux';
import { IState, IPaymentMethod } from '../models';
import * as NavigationStateService from '../services/navigationStateService';
import { Actions } from './PageActions';
import { handleSavePaymentMethodError } from './HandleSavePaymentMethodErrorAction';
import { savePaymentMethod as savePaymentMethodPost } from '../services/paymentsService';

export const savePaymentMethod = (signedToken: string) => async (dispatch: Dispatch<Action>, getState: () => { page: IState }) => {
	const props: IState = getState().page;
	const sessionKey = props.sessionKey;
	const sessionStore: NavigationStateService.ISessionState | null = NavigationStateService.getSessionStore(sessionKey);

	// Last minute validation
	if (!sessionStore) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: 'Unable to create payment method to charge. Session storage missing.',
			errorCode: 'paymentMethodFailure',
		});
		return;
	}
	if (!sessionStore.customerUrlString) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: "Unable to create payment method to charge. Session's Customer URL missing.",
			errorCode: 'paymentMethodFailure',
		});
		return;
	}

	const paymentMethod: IPaymentMethod | null = await savePaymentMethodPost(
		sessionStore.customerUrlString,
		signedToken,
		sessionKey,
		props.usingAlb,
		props.albRoute
	);
	if (paymentMethod && paymentMethod.isSuccessful) {
		dispatch({
			type: Actions.setPaymentMethod,
			data: { paymentMethod, hasExpandedPaymentEntry: false },
		});
	} else {
		handleSavePaymentMethodError(dispatch, props, paymentMethod);
	}
};
