import React from 'react';
import { CardDetails, PaymentMethodDetails } from 'src/selectors/ReviewAndConfirm.selectors';
import { Typography } from '@eftours/labs.ui-components';

interface IProps {
	paymentMethodDetails: PaymentMethodDetails;
	paymentMethodDetailsLabel: string;
}

const getCardBrandImage = (card: CardDetails) => {
	switch (card.cardBrand) {
		case 'MASTERCARD':
			return 'https://a.storyblok.com/f/65176/x/02823f1e6f/mastercard_brand.svg';
		case 'VISA':
			return 'https://a.storyblok.com/f/65176/x/02823f1e6f/visa_brand.svg';
		default:
			return undefined;
	}
};

export const PaymentMethodSummary: React.FC<IProps> = ({ paymentMethodDetails, paymentMethodDetailsLabel }) => {
	const VM = paymentMethodDetails;
	if (VM.type == 'CARD') {
		const cardBrandImageUrl = getCardBrandImage(VM);

		return (
			<>
				<Typography className="flex" variant="uilabel-bold">
					{paymentMethodDetailsLabel}
				</Typography>
				<Typography data-test="traveler-name" className="flex" variant="uilabel">
					{VM.firstName} {VM.lastName}
				</Typography>
				<Typography data-test="card-number-mask" className="flex" variant="uilabel">
					{VM.cardNumberMask}
				</Typography>
				<Typography data-test="card-exp" className="flex" variant="uilabel">
					Exp. {VM.expiration}
				</Typography>
				<Typography data-test="card-brand" className="flex" variant="uilabel">
					{cardBrandImageUrl ? <img src={cardBrandImageUrl} alt={VM.cardBrand} /> : VM.cardBrand}
				</Typography>
			</>
		);
	} else {
		return (
			<>
				<Typography className="flex" variant="uilabel-bold">
					{paymentMethodDetailsLabel}
				</Typography>
				<Typography data-test="traveler-name" className="flex" variant="uilabel">
					{VM.firstName} {VM.lastName}
				</Typography>
				<Typography data-test="account-number-mask" className="flex" variant="uilabel">
					{VM.accountNumberMask}
				</Typography>
			</>
		);
	}
};
