import { IStoreState } from 'src/models';
import { getCurrencyFormatter, getBalanceAfterPayment } from '.';
import { replaceTokens } from './TokenReplacer';

export const successPageViewModel = ({ page }: IStoreState) => {
	const formatter = getCurrencyFormatter(page);
	const paymentAmountFormatted = formatter.format(page.paymentAmount);
	return {
		balance: formatter.format(page.customer.amountDue),
		balanceAfterPayment: formatter.format(getBalanceAfterPayment(page)),
		paymentAmount: paymentAmountFormatted,
		transactionId: page.transactionId,
		correlationId: page.sessionKey,
		firstName: page.customer.firstName,
		copy: {
			...page.copy.success,
			message: replaceTokens(page.copy.success.message, {
				amount: paymentAmountFormatted,
				first_name: page.customer.firstName,
			}),
			confirmationMessage: replaceTokens(page.copy.success.confirmationMessage, {
				txn_id: page.transactionId || '',
			}),
			travelDetailsButton: page.copy.buttons.backToDetails,
		},
	};
};
