export const UsStatesAndTerritories: Array<[string, string]> = [
	['AL', 'Alabama'],
	['AK', 'Alaska'],
	['AS', 'American Samoa'],
	['AZ', 'Arizona'],
	['AR', 'Arkansas'],
	['CA', 'California'],
	['CO', 'Colorado'],
	['CT', 'Connecticut'],
	['DE', 'Delaware'],
	['DC', 'District of Columbia'],
	['FL', 'Florida'],
	['GA', 'Georgia'],
	['GU', 'Guam'],
	['HI', 'Hawaii'],
	['ID', 'Idaho'],
	['IL', 'Illinois'],
	['IN', 'Indiana'],
	['IA', 'Iowa'],
	['KS', 'Kansas'],
	['KY', 'Kentucky'],
	['LA', 'Louisiana'],
	['ME', 'Maine'],
	['MD', 'Maryland'],
	['MA', 'Massachusetts'],
	['MI', 'Michigan'],
	['MN', 'Minnesota'],
	['MS', 'Mississipi'],
	['MO', 'Missouri'],
	['MT', 'Montana'],
	['NE', 'Nebraska'],
	['NV', 'Nevada'],
	['NH', 'New Hampshire'],
	['NJ', 'New Jersey'],
	['NM', 'New Mexico'],
	['NY', 'New York'],
	['NC', 'North Carolina'],
	['ND', 'North Dakota'],
	['MP', 'Northern Mariana Islands'],
	['OH', 'Ohio'],
	['OK', 'Oklahoma'],
	['OR', 'Oregon'],
	['PA', 'Pennsylvania'],
	['PR', 'Puerto Rico'],
	['RI', 'Rhode Island'],
	['SC', 'South Carolina'],
	['SD', 'South Dakota'],
	['TN', 'Tennessee'],
	['TX', 'Texas'],
	['UT', 'Utah'],
	['VT', 'Vermont'],
	['VA', 'Virginia'],
	['VI', 'Virgin Islands'],
	['WA', 'Washington'],
	['WV', 'West Virginia'],
	['WI', 'Wisconsin'],
	['WY', 'Wyoming'],
];

export const Provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
