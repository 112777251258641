import { Dispatch, Action } from 'redux';
import { IState, IPaymentMethod } from '../models';
import { Actions } from './PageActions';

export const handleSavePaymentMethodError = (dispatch: Dispatch<Action>, props: IState, result: IPaymentMethod | null): any => {
	if (result === null) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: 'Failed to save payment method. No response.',
			errorCode: 'paymentMethodFailure',
		});
		return;
	}

	const errorMessage: string | null = result.errorMessages ? result.errorMessages[0] : null;
	const errorCode: string = props.copy.error.codes[result.siteErrorCode] ? result.siteErrorCode : 'paymentMethodFailure';

	dispatch({
		type: Actions.errorOccured,
		errorMessage,
		errorCode,
	});
};
