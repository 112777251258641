import React from 'react';
import { SelectInput } from '@eftours/labs.ui-components';
import { FormRow as Row, FormColumn as Col } from './FormRow';
import { ValidTextInput } from './ValidTextInput';
import { Contracts } from '@eftours/tokenization-client-library';
import { UsStatesAndTerritories, Provinces } from './StateProvinceLookup';
import { useSelector } from 'react-redux';
import { billingAddressSelector } from 'src/selectors';

interface Props {
	AddressFields: Contracts.RequiredBillingAddressFields;
	updateBillingAddress: (address: Contracts.RequiredBillingAddressFields) => void;
	validation: Contracts.ValidationModel<Contracts.BillingAddress> | undefined;
}

export const BillingAddressForm: React.FunctionComponent<Props> = (props) => {
	const { copy, language } = useSelector(billingAddressSelector);
	const address = props.AddressFields;

	const update = (toUpdate: (value: string) => Partial<Contracts.BillingAddress>) => {
		return (e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
			props.updateBillingAddress({ ...address, ...toUpdate(e.currentTarget.value) });
		};
	};
	const getStateProvinceOptions = () => {
		if (address.country === 'CA') {
			return Provinces.map((x) => [x, x] as [string, string]);
		} else {
			return UsStatesAndTerritories;
		}
	};
	const validation = props.validation || {};

	return (
		<>
			<ValidTextInput
				validation={validation.street1}
				onChange={update((value) => ({ street1: value }))}
				value={address.street1}
				label={copy.streetLabel}
				id="address1"
			/>
			<ValidTextInput
				validation={validation.city}
				onChange={update((value) => ({ city: value }))}
				value={address.city}
				label={copy.cityLabel}
				id="city"
			/>
			<Row>
				<Col size={1}>
					<SelectInput
						data-testid="state-selector"
						value={address.state}
						onChange={update((value) => ({ state: value }))}
						blankEntry={true}
						name="state"
						id="state"
						options={getStateProvinceOptions()}
						label={copy.stateLabel}
					/>
				</Col>
				<Col size={1}>
					<ValidTextInput
						validation={validation.postalCode}
						onChange={update((value) => ({ postalCode: value }))}
						value={address.postalCode}
						label={copy.postalCodeLabel}
						id="postal-code"
						mask="ZipCode"
						// Component library does not export the type it expects here
						locale={language as 'en-US' | 'en-CA' | 'fr-CA'}
					/>
				</Col>
			</Row>
		</>
	);
};
