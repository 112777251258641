import { IState } from 'src/models';

export * from './ReviewAndConfirm.selectors';
export * from './SuccessPage.selectors';
export * from './PaymentEntryViewModel.selectors';
export * from './ErrorViewModel.selectors';
export * from './TokenReplacer';
export * from './ValidationErrorsViewModel.selectors';
export * from './PreAuthorizedDebitViewModel.selector';
export * from './LayoutViewModel.selectors';

export const getCurrencyFormatter = ({ languageCode, customer }: IState) => {
	return Intl.NumberFormat(languageCode, { style: 'currency', currency: customer.currencyCode });
};
export const getBalanceAfterPayment = (state: IState) => {
	if (isNaN(state.paymentAmount)) {
		return state.customer.amountDue;
	} else {
		return state.customer.amountDue - state.paymentAmount;
	}
};
