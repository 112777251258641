import { Dispatch, Action } from 'redux';
import { IState } from '../models';
import * as NavigationStateService from '../services/navigationStateService';
import { Guid } from 'guid-typescript';
import * as Moment from 'moment';

export enum Actions {
	enableSpinner = 'ENABLE_SPINNER',
	disableSpinner = 'DISABLE_SPINNER',
	customerDataLoaded = 'PAYMENT_DATA_LOADED',
	selectLanguage = 'SELECT_LANGUAGE',
	setPaymentAmount = 'SET_PAYMENT_AMOUNT',
	errorOccured = 'ERROR_OCCURED',
	successfulPayment = 'SUCCESSFUL_PAYMENT',
	setPaymentMethod = 'PAYMENT_METHOD_SAVED',
	setSessionKeyGuid = 'SESSION_KEY_SET',
	clearErrors = 'CLEAR_ERRORS',
	setPaymentReadOnly = 'IS_PAYMENT_REQUIRED',
	setLoadBalancer = 'SET_LB',
	setCopy = 'SET_COPY',
	setHasExpandedPaymentEntry = 'SET_HAS_EXPANDED_PAYMENT_ENTRY',
}

export const setTimer = (dispatch: Dispatch<Action>, sessionStore: NavigationStateService.ISessionState | null, getState: () => { page: IState }) => {
	if (sessionStore) {
		const now = Moment();
		const dateToCheck = Moment(sessionStore.expirationDate);
		const difference = dateToCheck.diff(now).valueOf();
		setTimeout(() => {
			const props: IState = getState().page;
			if (tryHandleSessionExpiration(dispatch, props.sessionKey, props.isSuccess, props.isError)) {
				return;
			}
		}, difference);
	}
};

export const tryHandleSessionExpiration = (dispatch: Dispatch<Action>, sessionKey: Guid, isSuccess: boolean, isError: boolean): boolean => {
	if (isError || isSuccess) {
		return false;
	}

	if (NavigationStateService.isSessionStoreExpired(sessionKey)) {
		dispatch({
			type: Actions.setPaymentMethod,
			data: { paymentMethod: null },
		});
		dispatch({ type: Actions.errorOccured, errorMessage: 'Customer Session Expired.', errorCode: 'sessionExpired' });
		return true;
	}

	return false;
};

export const clearErrors = async (dispatch: Dispatch<Action>, getState: () => { page: IState }) => {
	const props: IState = getState().page;
	const sessionStore = NavigationStateService.getSessionStore(props.sessionKey);

	if (sessionStore) {
		sessionStore.errorCode = null;
		NavigationStateService.setSessionStore(props.sessionKey, sessionStore);
	}
	dispatch({
		type: Actions.clearErrors,
	});
};

export const setHasExpandedPaymentEntry = (isExpanded: boolean) => async (dispatch: Dispatch<Action>, _getState: () => { page: IState }) => {
	dispatch({ type: Actions.setHasExpandedPaymentEntry, data: isExpanded });
};
