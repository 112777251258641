import React from 'react';
import { TokenizationClientProvider, TokenizationClientProviderProps } from '@eftours/tokenization-client-library';
import { IFrameHost } from './IFrameHost';
import { useSelector } from 'react-redux';
import { paymentEntryFormViewModel } from 'src/selectors';
import { BusinessGroup } from 'src/models/BusinessGroup';
import { Typography } from '@eftours/labs.ui-components';

export const PaymentEntryPage: React.FunctionComponent = () => {
	const { businessGroup: siteCode, tokenizerUrl, initialFrameSettings, billingAddress, copy, anonymize } = useSelector(paymentEntryFormViewModel);

	const tokenizerProps: TokenizationClientProviderProps = {
		tokenServerUrl: tokenizerUrl,
		initialFrameSettings,
	};

	const requiresPad = siteCode === BusinessGroup.etca;
	return (
		<>
			<div className="form-wrapper-padding">
				<TokenizationClientProvider {...tokenizerProps}>
					<Typography variant="header2">{copy.addPaymentMethodLabel}</Typography>
					<IFrameHost
						correlationId={tokenizerProps.initialFrameSettings.settings.correlationId}
						billingAddress={billingAddress}
						isPadRequired={requiresPad}
						anonymize={anonymize}
					/>
				</TokenizationClientProvider>
			</div>
		</>
	);
};
