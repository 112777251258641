export enum Language {
	english = 'en-US',
	frenchCanadian = 'fr-CA',
	englishCanadian = 'en-CA',
}

export enum AlbRoute {
	english = '/makeapayment',
	french = '/makeapayment',
}

export interface ICopy {
	language: Language;

	siteTitle: string;
	confirmLeaveWithoutPayment: string;

	header: {
		secureCheckoutLabel: string;
	};

	balance: {
		currentLabel: string;
		newLabel: string;
	};

	paymentAmount: {
		header: string;
		subheader: string;
		paymentAmount: string;
		validations: {
			amountLargerThanBalance: string;
			amountLessOrEqualToZero: string;
		};
	};

	amount: {
		title: string;
	};

	error: {
		title: string;
		codes: {
			amountLargerThanBalance: string;
			amountLessOrEqualToZero: string;
			badSessionPleaseReload: string;
			sessionExpired: string;
			initialLoadFailure: string;
			noCustomerPayload: string;
			paymentMethodExpired: string;
			paymentMethodFailure: string;
			finalPaymentBadResponse: string;
			paymentMethodMissing: string;
			unknown: string;
		};
	};

	tokenizerValidation: {
		invalidBin: string;
		invalidCvv: string;
		invalidCity: string;
		invalidStreet: string;
		invalidLastName: string;
		invalidFirstName: string;
		invalidCardNumber: string;
		invalidPostalCode: string;
		invalidAccountNumber: string;
		invalidRoutingNumber: string;
		invalidTransitNumber: string;
		invalidExpirationDate: string;
		invalidInstitutionNumber: string;
		tokenizationFailed: string;
	};

	success: {
		title: string;
		message: string;
		messageForNulls: string;
		confirmationMessage: string;
		updatedBalance: string;
		chargedToday: string;
	};

	review: {
		title: string;
		paymentMethodLabel: string;
		verifyPaymentDetails: string;
		balanceAfterPaymentLabel: string;
		paymentDetailsLabel: string;
	};

	paymentMethod: {
		addPaymentMethodLabel: string;
		checkingLabel: string;
		expirationDateLabel: string;
	};
	padAgreement: {
		padLabel: string;
		padInstructions: string;
		agreementText: string;
		padAgreement1: string;
		padAgreement2: string;
		padAgreement3: string;
		padAgreement4: string;
		padAgreement5: string;
		padAgreement6: string;
	};

	address: {
		addressNameLabel: string;
		cityLabel: string;
		streetLabel: string;
		stateLabel: string;
		postalCodeLabel: string;
		useExistingAddressLabel: string;
		useNewAddressLabel: string;
	};

	buttons: {
		next: string;
		previous: string;
		submit: string;
		finished: string;
		tryAgain: string;
		contactUs: string;
		cancel: string;
		payFullBalance: string;
		backToDetails: string;
		edit: string;
		review: string;
	};
}
