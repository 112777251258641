import React, { useState } from 'react';
import { RadioGroup, Radio, Typography } from '@eftours/labs.ui-components';
import { BillingAddressForm } from './BillingAddressForm';
import { Contracts } from '@eftours/tokenization-client-library';
import { useSelector } from 'react-redux';
import { billingAddressSelector } from 'src/selectors';

interface Props {
	validation: Contracts.ValidationModel<Contracts.BillingAddress> | undefined;
	billingAddress: Contracts.RequiredBillingAddressFields;
	updateBillingAddressInFrame: (updateContent: Contracts.FrameUpdateRequest) => void;
	anonymize: boolean;
}

export const BillingAddressSelect: React.FunctionComponent<Props> = (props) => {
	const {
		copy: { useExistingAddressLabel, useNewAddressLabel },
	} = useSelector(billingAddressSelector);
	const [billingAddress, setBillingAddress] = useState({ ...props.billingAddress });
	const [useExisting, setUseExisting] = useState(true);

	const updateAddressFormState = (useExisting: string) => {
		if (useExisting === 'true') {
			setUseExisting(true);
			updateBillingAddress({ ...props.billingAddress });
		} else {
			setUseExisting(false);
			updateBillingAddress({
				country: billingAddress.country,
				city: '',
				postalCode: '',
				state: billingAddress.state,
				street1: '',
			});
		}
	};
	const updateBillingAddress = (address: Contracts.RequiredBillingAddressFields) => {
		setBillingAddress(address);
		props.updateBillingAddressInFrame({ billingAddress: { ...address } });
	};

	const renderBillingAddressSummary = (address: Contracts.RequiredBillingAddressFields) => {
		return (
			<>
				<Typography variant="uilabel-bold">{useExistingAddressLabel}</Typography>
				<Typography variant="uilabel">{address.street1}</Typography>
				<Typography variant="uilabel">
					{address.city}, {address.state} {address.postalCode}
				</Typography>
			</>
		);
	};

	if (props.anonymize) {
		return <BillingAddressForm validation={props.validation} updateBillingAddress={updateBillingAddress} AddressFields={billingAddress} />;
	}

	return (
		<>
			<RadioGroup onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateAddressFormState(e.target.value)} name="billing-address-selector">
				<Radio checked={useExisting} value="true">
					{renderBillingAddressSummary(props.billingAddress)}
				</Radio>
				<Radio checked={!useExisting} value="false" label={useNewAddressLabel} />
			</RadioGroup>

			{!useExisting ? (
				<BillingAddressForm validation={props.validation} updateBillingAddress={updateBillingAddress} AddressFields={billingAddress} />
			) : null}
		</>
	);
};
