import * as React from 'react';
import { Typography, Button, Link } from '@eftours/labs.ui-components';
import { useSelector } from 'react-redux';
import { reviewAndConfirmSelectorViewModel } from 'src/selectors/ReviewAndConfirm.selectors';
import { Redirect, useHistory } from 'react-router-dom';
import { PaymentMethodSummary } from './PaymentMethodSummary';
import { navigateToAmount, navigateToPaymentEntryPage, navigateToSuccess } from 'src/navigation';
import { useThunkDispatch } from 'src/redux-thunk-adapter';
import { makePayment } from 'src/PageActions';

export const ReviewAndConfirm: React.FC = () => {
	const VM = useSelector(reviewAndConfirmSelectorViewModel);

	const dispatch = useThunkDispatch();

	if (!VM) {
		// TODO: Handle this case better
		// this is the: The user is on review and confirm without a save PM case.
		return <Redirect to="/" />;
	}

	const history = useHistory();
	const { billingAddress, paymentMethodDetails, balanceAfterPayment, paymentAmount, copy } = VM;

	const submitPayment = async () => {
		await dispatch(makePayment);
		navigateToSuccess({ correlationId: VM.correlationId, history });
	};

	const goBackToPaymentEntry = async () => {
		navigateToAmount({ correlationId: VM.correlationId.toString(), history });
	};

	return (
		<>
			<div className="form-wrapper-padding">
				<Typography variant="header2">{copy.title}</Typography>

				<Typography variant="body1">{copy.verifyPaymentDetails}</Typography>

				<div className="flex column soft-border align-text-bottom m-2 m-left-0 m-right-0">
					<div className="flex space-between bg-blue p-1">
						<Typography className="m-0" variant="header4">
							{copy.paymentAmount}
						</Typography>
						<Typography className="m-0" variant="header4" data-test="payment-amount">
							{paymentAmount}
						</Typography>
					</div>
					<div className="flex space-between p-1 p-top-1_5 p-bottom-1_5">
						<Typography variant="uilabel-bold">{copy.balanceAfterPaymentLabel}</Typography>
						<Typography variant="uilabel-bold" data-test="balance-after-payment">
							{balanceAfterPayment}
						</Typography>
					</div>
				</div>

				<div className="flex space-between fill underlined-row m-bottom-2">
					<Typography className="flex" variant="uilabel-bold">
						{copy.paymentMethodLabel}
					</Typography>
					<Link onClick={() => navigateToPaymentEntryPage({ history, correlationId: VM.correlationId })}>
						<Typography className="flex" variant="uilabel-bold" color="link">
							{copy.editButton}
						</Typography>
					</Link>
				</div>
				<div className="flex m-bottom-2">
					<div className="flex column flex-1">
						<PaymentMethodSummary paymentMethodDetails={paymentMethodDetails} paymentMethodDetailsLabel={copy.paymentDetailsLabel} />
					</div>
					<div className="flex column flex-1">
						<Typography className="flex" variant="uilabel-bold">
							{copy.billingAddressLabel}
						</Typography>
						<Typography data-test="billing-address-name" className="flex" variant="uilabel">
							{billingAddress.firstName} {billingAddress.lastName}
						</Typography>
						<Typography data-test="billing-address-street" className="flex" variant="uilabel">
							{billingAddress.street1}
						</Typography>
						<Typography data-test="billing-address-city-state" className="flex" variant="uilabel">
							{billingAddress.city},{billingAddress.state}
						</Typography>
						<Typography data-test="billing-address-postal-code" className="flex" variant="uilabel">
							{billingAddress.postalCode}
						</Typography>
					</div>
				</div>
				<div className="flex space-between m-bottom-3">
					<Button onClick={goBackToPaymentEntry} variant="secondary" data-test="backBtn" className="flex-1 m-2 m-bottom-0">
						{copy.previousButton}
					</Button>
					<Button onClick={submitPayment} variant="primary" className="flex-1 m-2 m-bottom-0" data-test="submitBtn">
						{copy.submitButton}
					</Button>
				</div>
			</div>
		</>
	);
};
