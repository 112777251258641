import { ICopy, Language } from './copy';
import { ICustomer } from './customer';
import { BusinessGroup } from './BusinessGroup';
import { IPaymentMethod } from './paymentMethod';
import { Guid } from 'guid-typescript';

export interface IState {
	sessionKey: Guid;
	copy: ICopy;
	customer: ICustomer;
	paymentAmount: number;
	transactionId: string | null;
	paymentMethod: IPaymentMethod | null;
	isSpinnerUp: boolean;
	isFatalError: false;
	isError: boolean;
	isSuccess: boolean;
	errorMessage: string;
	errorCode: ErrorCode | undefined;
	isPaymentAmountEditable: boolean;
	albRoute: string;
	usingAlb: boolean;
	hasExpandedPaymentEntry: boolean;
	languageCode: Language;
}

export type ErrorCode =
	| 'finalPaymentBadResponse'
	| 'paymentMethodFailure'
	| 'initialLoadFailure'
	| 'noCustomerPayload'
	| 'amountLessOrEqualToZero'
	| 'paymentMethodMissing'
	| 'badSessionPleaseReload'
	| 'sessionExpired'
	| 'initialLoadFailure'
	| 'unknown';

export const defaultState: IState = {
	sessionKey: Guid.create(),
	copy: {} as any, // If the copy doesn't load, neither does the app
	languageCode: Language.english,
	customer: {
		anonymize: false,
		amountDue: 0,
		businessUnit: '',
		currencyCode: '',
		enrolledDays: 0,
		enterPaymentMethodUrl: '',
		restrictedPaymentTypes: 0,
		firstName: '',
		lastName: '',
		productMarket: '',
		businessGroup: BusinessGroup.etus,
		supportPhoneNumber: '',
		billingAddress: {
			country: 'US',
			city: '',
			firstName: '',
			lastName: '',
			postalCode: '',
			state: '',
			street1: '',
		},
	},
	paymentAmount: 0,
	transactionId: null,
	paymentMethod: null,
	isSpinnerUp: true,
	isFatalError: false,
	isError: false,
	isSuccess: false,
	errorMessage: '',
	errorCode: undefined,
	isPaymentAmountEditable: false,
	albRoute: '',
	usingAlb: false,
	hasExpandedPaymentEntry: false,
};
