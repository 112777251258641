import * as React from 'react';
import { Typography, CurrencyInput, Button, ExpandingContent } from '@eftours/labs.ui-components';
import { useSelector } from 'react-redux';
import { paymentAmountEntryBoxViewModel } from 'src/selectors';
import { useThunkDispatch } from 'src/redux-thunk-adapter';
import { setPaymentAmount } from 'src/PageActions';
import { Language } from 'src/models';

export const PaymentAmountEntryBox: React.FC = () => {
	const { balance, paymentAmount, balanceAfterPayment, shouldWarnAboutOverpaying, copy, locale, languageCode, isPaymentAmountEditable } =
		useSelector(paymentAmountEntryBoxViewModel);
	const dispatch = useThunkDispatch();

	const [paymentAmountString, setPaymentAmountString] = React.useState(paymentAmount !== 0 ? paymentAmount.toString() : '');

	const updatePaymentAmount = async (amount: string) => {
		setPaymentAmountString(amount);

		let amountSpacesAndDollarSignAndSep = amount.replace(/\s|\$/g, '');
		if (languageCode == Language.frenchCanadian) {
			amountSpacesAndDollarSignAndSep = amountSpacesAndDollarSignAndSep.replace(/\./, '').replace(/,/g, '.');
		} else {
			amountSpacesAndDollarSignAndSep = amountSpacesAndDollarSignAndSep.replace(/,/g, '');
		}

		const amountNum = parseFloat(amountSpacesAndDollarSignAndSep);
		await dispatch(setPaymentAmount(amountNum));
	};

	const renderWarning = () => {
		return (
			<>
				<ExpandingContent open={shouldWarnAboutOverpaying}>
					<Typography className="red-text" variant="caption">
						{copy.cannotPayMoreThanBalanceWarning}
					</Typography>
					<div className="flex center-content m-top-1">
						<Button onClick={() => updatePaymentAmount(balance)} variant="primary" size="small">
							{copy.payFullBalanceButton}
						</Button>
					</div>
				</ExpandingContent>
			</>
		);
	};

	return (
		<>
			<div className="form-wrapper-padding">
				<Typography variant="header2">{copy.header}</Typography>
				<Typography variant="body1">{copy.subheader}</Typography>

				<div className="flex column soft-border align-text-center m-2">
					<div className="flex center-content bg-grey">
						<Typography className="m-0_5" variant="uilabel-bold">
							{copy.currentBalance}
						</Typography>
						<Typography className="m-0_5" variant="uilabel-bold" data-test="current-balance">
							{balance}
						</Typography>
					</div>
					<div className="flex space-between p-1 p-bottom-0">
						<Typography variant="uilabel-bold">{copy.paymentAmountLabel}</Typography>
						<div className="flex short-field m-left-1">
							<CurrencyInput
								disabled={!isPaymentAmountEditable}
								value={paymentAmountString}
								locale={locale}
								allowCents={true}
								onChange={(e) => updatePaymentAmount(e.currentTarget.value)}
								label=""
								id="payment-amount-entry"
							/>
						</div>
					</div>
					<div className="hr"></div>
					<div className="p-1">
						<div className="flex space-between">
							<Typography className="m-bottom-0" variant="header4">
								{copy.newBalance}
							</Typography>
							<Typography className="m-bottom-0" variant="header4" data-test="balance-after-payment">
								{!shouldWarnAboutOverpaying && balanceAfterPayment}
							</Typography>
						</div>

						{renderWarning()}
					</div>
				</div>
			</div>
		</>
	);
};
