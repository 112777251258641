import { IState, defaultState, ICopy } from './models';
import { Actions } from './PageActions/PageActions';
import setSessionKeyGuid = Actions.setSessionKeyGuid;
import { logError } from './services/loggingService';

export default function PageReducer(state: IState = defaultState, action: any): IState {
	switch (action.type) {
		case Actions.setCopy:
			document.title = (<ICopy>action.data.copy)?.siteTitle;
			return Object.assign({}, state, { copy: action.data.copy });
		case Actions.enableSpinner:
			return Object.assign({}, state, { isSpinnerUp: true });
		case Actions.disableSpinner:
			return Object.assign({}, state, { isSpinnerUp: false });
		case Actions.customerDataLoaded:
			return Object.assign({}, state, { customer: action.data.customer });
		case Actions.successfulPayment:
			return Object.assign({}, state, { isSuccess: true, transactionId: action.data.transactionId });
		case Actions.selectLanguage:
			return Object.assign({}, state, { languageCode: action.data.language });
		case Actions.setPaymentAmount:
			return Object.assign({}, state, { paymentAmount: action.data.paymentAmount });
		case Actions.errorOccured: {
			const isFatalError: boolean = ['badSessionPleaseReload', 'sessionExpired', 'noCustomerPayload'].indexOf(action.errorCode) > -1;
			const loggedMessage: string = action.errorMessage && action.errorMessage.length > 0 ? action.errorMessage : action.errorCode;
			logError(state.sessionKey, loggedMessage, action.errorCode);
			return Object.assign({}, state, { isSpinnerUp: false, isError: true, errorMessage: loggedMessage, errorCode: action.errorCode, isFatalError });
		}
		case Actions.clearErrors:
			return Object.assign({}, state, { isSpinnerUp: true, isError: false, errorMessage: null, errorCode: null });
		case Actions.setPaymentMethod:
			return Object.assign({}, state, {
				paymentMethod: action.data.paymentMethod,
				hasExpandedPaymentEntry: action.data.hasExpandedPaymentEntry,
			});
		case setSessionKeyGuid:
			return Object.assign({}, state, { sessionKey: action.data.sessionKey });
		case Actions.setPaymentReadOnly:
			return Object.assign({}, state, { isPaymentAmountEditable: action.data.isPaymentAmountEditable });
		case Actions.setLoadBalancer:
			return Object.assign({}, state, { usingAlb: action.data.usingAlb });
		case Actions.setHasExpandedPaymentEntry:
			return Object.assign({}, state, { hasExpandedPaymentEntry: action.data });
		default:
			return state;
	}
}
