import { Dispatch, Action } from 'redux';
import { IState, ITransactionResponse } from '../models';
import { Actions } from './PageActions';

export const handleMakePaymentError = (dispatch: Dispatch<Action>, props: IState, result: ITransactionResponse | null): any => {
	if (result === null) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: 'No response while submitting final payment',
			errorCode: 'finalPaymentBadResponse',
		});
		return;
	}

	const errorMessage: string | null = result.errorMessages ? result.errorMessages[0] : null;
	const errorCode: string = props.copy.error.codes[result.siteErrorCode] ? result.siteErrorCode : 'finalPaymentBadResponse';

	dispatch({
		type: Actions.errorOccured,
		errorMessage,
		errorCode,
	});
};
