import { Button, Typography } from '@eftours/labs.ui-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from 'src/models';
import { getSecureSiteAddress, navigateToSecureSite } from 'src/navigation';
import arrowLeftIcon from '../images/arrow_left_black.svg';

export const ReturnToTravelDetailsButton: React.FC = () => {
	const { correlationId, travelDetailsButton, isSpinnerUp, confirmLeaveWithoutPayment, isTransactionComplete } = useSelector(({ page }: IStoreState) => ({
		isSpinnerUp: page?.isSpinnerUp,
		travelDetailsButton: page?.copy?.buttons?.backToDetails,
		confirmLeaveWithoutPayment: page?.copy?.confirmLeaveWithoutPayment,
		correlationId: page?.sessionKey,
		isTransactionComplete: !!page?.transactionId,
	}));
	const travelDetailsUrl = getSecureSiteAddress(correlationId);

	if (!travelDetailsUrl || isSpinnerUp === undefined || isSpinnerUp || isTransactionComplete) {
		return null;
	}
	const onClick = () => {
		if (confirmLeaveWithoutPayment) {
			if (confirm(confirmLeaveWithoutPayment)) {
				navigateToSecureSite({ correlationId });
			}
		} else {
			navigateToSecureSite({ correlationId });
		}
	};

	return (
		<>
			<div className="return-to-travel-details-button">
				<Button onClick={() => onClick()} variant="unstyled">
					<div className="img-wrapper">
						<img src={arrowLeftIcon} className="inline-icon" />
					</div>
					<Typography variant="body1">{travelDetailsButton}</Typography>
				</Button>
			</div>
		</>
	);
};
