import { IStoreState, IPaymentMethod, CardBrand } from 'src/models';
import { getCurrencyFormatter, getBalanceAfterPayment } from '.';
import { replaceTokens } from './TokenReplacer';

interface SharedPaymentDetails {
	firstName: string;
	lastName: string;
	type: 'CARD' | 'CHECK';
}
export interface CardDetails extends SharedPaymentDetails {
	type: 'CARD';
	cardNumberMask: string;
	expiration: string;
	cardBrand: CardBrand;
}
interface CheckDetails extends SharedPaymentDetails {
	type: 'CHECK';
	accountNumberMask: string;
}

export type PaymentMethodDetails = CardDetails | CheckDetails;

const createPaymentMethodDetails = (pm: IPaymentMethod): PaymentMethodDetails => {
	if (pm.isCredit) {
		return <CardDetails>{
			cardBrand: pm.cardBrand,
			cardNumberMask: pm.maskedAccountSummary,
			expiration: pm.expirationDate,
			firstName: pm.billingFirstName,
			lastName: pm.billingLastName,
			type: 'CARD',
		};
	} else {
		return <CheckDetails>{
			accountNumberMask: pm.maskedAccountSummary,
			firstName: pm.billingFirstName,
			lastName: pm.billingLastName,
			type: 'CHECK',
		};
	}
};

export const reviewAndConfirmSelectorViewModel = ({ page }: IStoreState) => {
	if (page.paymentMethod == null) {
		return null;
	}
	const formatter = getCurrencyFormatter(page);

	return {
		balance: formatter.format(page.customer.amountDue),
		balanceAfterPayment: formatter.format(getBalanceAfterPayment(page)),
		paymentAmount: formatter.format(page.paymentAmount),
		billingAddress: page.paymentMethod.billingAddress,
		correlationId: page.sessionKey,
		paymentMethodDetails: createPaymentMethodDetails(page.paymentMethod),
		copy: {
			balanceAfterPaymentLabel: page.copy.review.balanceAfterPaymentLabel,
			title: page.copy.review.title,
			submitButton: page.copy.buttons.submit,
			previousButton: page.copy.buttons.previous,
			editButton: page.copy.buttons.edit,
			verifyPaymentDetails: replaceTokens(page.copy.review.verifyPaymentDetails, {
				first_name: page.customer.firstName,
			}),
			paymentAmount: page.copy.paymentAmount.paymentAmount,
			billingAddressLabel: page.copy.address.addressNameLabel,
			paymentMethodLabel: page.copy.review.paymentMethodLabel,
			paymentDetailsLabel: page.copy.review.paymentDetailsLabel,
		},
	};
};
