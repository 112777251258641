import { IStoreState } from 'src/models';

export const errorViewModel = ({ page }: IStoreState) => {
	return {
		isError: page.isError,
		correlationId: page.sessionKey,
		isFatalError: page.isFatalError,
		errorCode: page.errorCode,
		errorMessage: page.errorMessage,
		copy: {
			...page.copy.error,
			tryAgainButton: page.copy.buttons.tryAgain,
		},
	};
};
