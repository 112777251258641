import React from 'react';
import { useSelector } from 'react-redux';
import { errorViewModel } from 'src/selectors';
import { Redirect, useHistory } from 'react-router-dom';
import { Typography, Button } from '@eftours/labs.ui-components';
import { useThunkDispatch } from 'src/redux-thunk-adapter';
import { clearErrors, loadPageData } from 'src/PageActions';
import { navigateToAmount } from 'src/navigation';

export const ErrorPage: React.FC = () => {
	const { errorCode, errorMessage: fallbackMessage, isError, isFatalError, correlationId, copy } = useSelector(errorViewModel);

	if (!isError || !errorCode) {
		return <Redirect to="/" />;
	}
	const dispatch = useThunkDispatch();
	const history = useHistory();

	const tryAgain = async () => {
		await dispatch(clearErrors);
		await dispatch(loadPageData);
		navigateToAmount({ correlationId: correlationId.toString(), history });
	};

	const errorMessage = copy.codes[errorCode];

	return (
		<>
			<div className="flex column form-wrapper-padding m-bottom-2">
				<Typography variant="header2" data-test="error-title">
					{copy.title}
				</Typography>

				<Typography variant="body2" data-test="error-message">
					{/* If we can't find a CMS error message to use, just fallback to the developer one */}
					{errorMessage || fallbackMessage}
				</Typography>

				<Typography color="tertiary" variant="caption" data-test="error-code">
					{errorCode}
				</Typography>
				<Typography color="tertiary" variant="caption" data-test="session-id">
					{correlationId.toString()}
				</Typography>

				{isFatalError ? null : (
					<Button className="m-2 m-bottom-0" onClick={() => tryAgain()} data-test="try-again-btn" variant="primary">
						{copy.tryAgainButton}
					</Button>
				)}
			</div>
		</>
	);
};
