import { ICopy, ISiteContent, Language } from '../models';
import { StoryblokService } from '../services/storyblokService';
import { StoryData, StoryblokResult } from 'storyblok-js-client';

const storyblokService = new StoryblokService(window.clientRuntimeConfig.storyblokToken);

export const loadStoryblokContent = async (languageCode: Language, _businessGroup?: string): Promise<{ copy: ICopy }> => {
	await storyblokService.initializeCache();

	return storyblokService
		.get('public-one-time-payments/default', languageCode, {
			per_page: 1,
			page: 1,
		})
		.then((response: StoryblokResult | undefined) => {
			const story: StoryData<ISiteContent> = response?.data.story;

			window.console.log(story);

			const copy: ICopy = {
				language: languageCode,
				confirmLeaveWithoutPayment: story.content.confirm_leave_without_payment,
				siteTitle: story.content.site_title,
				amount: {
					title: '', // todo
				},
				error: {
					title: story.content.errors[0].error_title,
					codes: {
						amountLargerThanBalance: story.content.errors[0].amount_larger_than_balance,
						amountLessOrEqualToZero: story.content.errors[0].amount_less_or_equal_to_zero,
						badSessionPleaseReload: story.content.errors[0].bad_session_please_reload,
						sessionExpired: story.content.errors[0].session_expired,
						paymentMethodExpired: story.content.errors[0].payment_method_expired,
						paymentMethodFailure: story.content.errors[0].payment_method_failure,
						paymentMethodMissing: story.content.errors[0].payment_method_missing,
						initialLoadFailure: story.content.errors[0].initial_load_failure,
						noCustomerPayload: story.content.errors[0].no_customer_payload,
						finalPaymentBadResponse: story.content.errors[0].final_payment_bad_response,
						unknown: story.content.errors[0].unknown,
					},
				},
				address: {
					addressNameLabel: story.content.address[0].billing_address_label,
					cityLabel: story.content.address[0].city_label,
					streetLabel: story.content.address[0].street_address_label,
					postalCodeLabel: story.content.address[0].postal_code_label,
					stateLabel: story.content.address[0].state_label,
					useExistingAddressLabel: story.content.address[0].use_existing_address_label,
					useNewAddressLabel: story.content.address[0].use_new_address_label,
				},
				padAgreement: {
					padLabel: story.content.pad_agreement[0].pad_label,
					agreementText: story.content.pad_agreement[0].agreement_text,
					padAgreement1: story.content.pad_agreement[0].pad_agreement_1,
					padAgreement2: story.content.pad_agreement[0].pad_agreement_2,
					padAgreement3: story.content.pad_agreement[0].pad_agreement_3,
					padAgreement4: story.content.pad_agreement[0].pad_agreement_4,
					padAgreement5: story.content.pad_agreement[0].pad_agreement_5,
					padAgreement6: story.content.pad_agreement[0].pad_agreement_6,
					padInstructions: story.content.pad_agreement[0].pad_instructions,
				},
				success: {
					title: story.content.success[0].title,
					messageForNulls: story.content.success[0].message_for_nulls,
					chargedToday: story.content.success[0].charged_today,
					confirmationMessage: story.content.success[0].confirmation_number,
					message: story.content.success[0].message,
					updatedBalance: story.content.success[0].updated_balance,
				},
				review: {
					title: story.content.review_and_submit[0].header,
					paymentMethodLabel: story.content.review_and_submit[0].payment_method_label,
					verifyPaymentDetails: story.content.review_and_submit[0].verify_payment_details,
					paymentDetailsLabel: story.content.review_and_submit[0].payment_details_label,
					balanceAfterPaymentLabel: story.content.balance_after_payment_label,
				},
				header: {
					secureCheckoutLabel: story.content.secure_payment_text,
				},
				balance: {
					currentLabel: story.content.balance_label,
					newLabel: story.content.balance_after_payment_label,
				},
				paymentAmount: {
					header: story.content.landing_header,
					subheader: story.content.landing_text,
					paymentAmount: story.content.payment_label,
					validations: {
						amountLargerThanBalance: story.content.errors[0].amount_larger_than_balance,
						amountLessOrEqualToZero: story.content.errors[0].amount_less_or_equal_to_zero,
					},
				},
				paymentMethod: {
					addPaymentMethodLabel: story.content.add_payment_method,
					checkingLabel: story.content.checking_label,
					expirationDateLabel: story.content.expiration_date_label,
				},
				buttons: {
					cancel: story.content.buttons[0].cancel,
					contactUs: story.content.buttons[0].contact_us,
					finished: story.content.buttons[0].finished,
					next: story.content.buttons[0].finished,
					previous: story.content.buttons[0].previous,
					submit: story.content.buttons[0].submit,
					tryAgain: story.content.buttons[0].try_again,
					payFullBalance: story.content.buttons[0].pay_full_balance,
					backToDetails: story.content.buttons[0].back_to_details,
					edit: story.content.buttons[0].edit,
					review: story.content.buttons[0].review,
				},
				tokenizerValidation: {
					tokenizationFailed: story.content.tokenizer_validation_messages[0].tokenization_failed,
					invalidBin: story.content.tokenizer_validation_messages[0].invalid_bin,
					invalidCvv: story.content.tokenizer_validation_messages[0].invalid_cvv,
					invalidCity: story.content.tokenizer_validation_messages[0].invalid_city,
					invalidStreet: story.content.tokenizer_validation_messages[0].invalid_street,
					invalidLastName: story.content.tokenizer_validation_messages[0].invalid_last_name,
					invalidFirstName: story.content.tokenizer_validation_messages[0].invalid_first_name,
					invalidCardNumber: story.content.tokenizer_validation_messages[0].invalid_card_number,
					invalidPostalCode: story.content.tokenizer_validation_messages[0].invalid_postal_code,
					invalidAccountNumber: story.content.tokenizer_validation_messages[0].invalid_account_number,
					invalidRoutingNumber: story.content.tokenizer_validation_messages[0].invalid_routing_number,
					invalidTransitNumber: story.content.tokenizer_validation_messages[0].invalid_transit_number,
					invalidExpirationDate: story.content.tokenizer_validation_messages[0].invalid_expiration_date,
					invalidInstitutionNumber: story.content.tokenizer_validation_messages[0].invalid_institution_number,
				},
			};

			return { copy: copy };
		});
};
