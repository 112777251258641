import { Dispatch, Action } from 'redux';
import { IState, ICustomer, Language } from '../models';
import { getCustomerData } from '../services/paymentsService';
import * as NavigationStateService from '../services/navigationStateService';
import { Guid } from 'guid-typescript';
import { Actions, setTimer, tryHandleSessionExpiration } from './PageActions';
import { loadStoryblokContent } from './StoryblokContentLoader';
import { BusinessGroup } from 'src/models/BusinessGroup';

export const loadPageData = async (dispatch: Dispatch<Action>, getState: () => { page: IState }) => {
	dispatch({ type: Actions.enableSpinner });

	const correlationId: Guid = NavigationStateService.getCorrelationIdFromUrlOrDefault();
	let sessionStore: NavigationStateService.ISessionState | null = NavigationStateService.getSessionStore(correlationId);
	if (!sessionStore) {
		sessionStore = NavigationStateService.createSessionStore(correlationId);
	}
	sessionStore = NavigationStateService.updateSessionStoreFromUrlAndSetExpiration(correlationId, sessionStore as NavigationStateService.ISessionState);

	// We need to load some copy as early as possible for errors, we can overwrite it later
	const copy = await getCopy(sessionStore?.language || 'en-US', undefined);
	dispatch({ type: Actions.setCopy, data: copy });

	if (!sessionStore) {
		dispatch({ type: Actions.errorOccured, errorMessage: 'Error creating session store', errorCode: 'badSessionPleaseReload' });
		return;
	}

	dispatch({
		type: Actions.setSessionKeyGuid,
		data: { sessionKey: correlationId },
	});

	dispatch({
		type: Actions.selectLanguage,
		data: { language: sessionStore.language },
	});

	dispatch({
		type: Actions.setPaymentReadOnly,
		data: { isPaymentAmountEditable: sessionStore.isPaymentAmountEditable },
	});

	if (sessionStore.errorCode) {
		dispatch({
			type: Actions.errorOccured,
			errorMessage: 'Found an error in your session storage. Did you reload the error page?',
			errorCode: sessionStore.errorCode,
		});
		return;
	}

	const props: IState = getState().page;
	const usingAlb: boolean = window.location.pathname.includes(props.albRoute);
	if (usingAlb) {
		dispatch({
			type: Actions.setLoadBalancer,
			data: { usingAlb: true },
		});
	}

	let customer: ICustomer | null = null;
	if (sessionStore.customerUrlString) {
		customer = await getCustomerData(sessionStore.customerUrlString, correlationId, sessionStore.language, usingAlb, props.albRoute);
		if (customer) {
			dispatch({
				type: Actions.customerDataLoaded,
				data: { customer },
			});

			dispatch({
				type: Actions.setPaymentAmount,
				data: { paymentAmount: sessionStore.paymentAmount },
			});

			// This was a previously successful transaction
			if (sessionStore.transactionId) {
				dispatch({ type: Actions.successfulPayment, data: { transactionId: sessionStore.transactionId } });
			}

			setTimer(dispatch, sessionStore, getState);
			if (tryHandleSessionExpiration(dispatch, correlationId, false, false)) {
				return;
			}
			// dispatch({ type: Actions.disableSpinner });
		} else {
			dispatch({ type: Actions.errorOccured, errorMessage: 'Failed to load customer from api', errorCode: 'initialLoadFailure' });
		}
	} else {
		dispatch({ type: Actions.errorOccured, errorMessage: 'No customer payload found', errorCode: 'noCustomerPayload' });
	}

	if (customer) {
		const copy = await getCopy(sessionStore.language, customer.businessGroup);
		dispatch({ type: Actions.setCopy, data: copy });
		dispatch({ type: Actions.disableSpinner });
	}
};

const getCopy = async (languageFromSession: string | null, businessGroup: BusinessGroup | undefined) => {
	const language: Language =
		languageFromSession === Language.english
			? Language.english
			: languageFromSession === Language.frenchCanadian
			? Language.frenchCanadian
			: Language.englishCanadian; // todo make this better

	const copy = await loadStoryblokContent(language, businessGroup);
	return copy;
};
