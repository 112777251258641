import * as Navigation from './services/navigationStateService';
import { History } from 'history';
import { Guid } from 'guid-typescript';

export const navigateToAmount = (props: { correlationId: string | undefined; history: History<any>; usingAlb?: boolean; albRoute?: string }) => {
	const amountRoute = props.usingAlb ? props.albRoute + '/amount' : '/amount';
	if (props.correlationId) {
		props.history.push(amountRoute + '?c=' + props.correlationId);
	} else {
		props.history.push(amountRoute);
	}
};

export const navigateToReview = (props: { correlationId: string | undefined; history: History<any>; usingAlb?: boolean; albRoute?: string }) => {
	const reviewRoute = props.usingAlb ? props.albRoute + '/review' : '/review';
	if (props.correlationId) {
		props.history.push(reviewRoute + '?c=' + props.correlationId);
	} else {
		props.history.push(reviewRoute);
	}
};

export const navigateToPaymentEntryPage = (props: any) => {
	const successRoute = props.usingAlb ? props.albRoute + '/payment-entry' : '/payment-entry';
	if (props.correlationId) {
		props.history.push(successRoute + '?c=' + props.correlationId);
	} else {
		props.history.push(successRoute);
	}
};

export const navigateToSuccess = (props: any) => {
	const successRoute = props.usingAlb ? props.albRoute + '/success' : '/success';
	if (props.correlationId) {
		props.history.push(successRoute + '?c=' + props.correlationId);
	} else {
		props.history.push(successRoute);
	}
};

export const navigateToError = (props: any) => {
	const errorRoute = props.usingAlb ? props.albRoute + '/error' : '/error';
	if (props.sessionKey) {
		props.history.push(errorRoute + '?c=' + props.sessionKey);
	} else {
		props.history.push(errorRoute);
	}
};

export const getSecureSiteAddress = (correlationId: Guid) => {
	const session = Navigation.getSessionStore(correlationId);
	if (session) {
		return session.returnUrl;
	} else {
		return null;
	}
};

export const navigateToSecureSite = (props: { correlationId: Guid }) => {
	const address = getSecureSiteAddress(props.correlationId);

	if (address) {
		window.location.href = address;
	} else {
		navigateToError(props);
	}
};
