import React from 'react';
import { ValidationMessage } from '@eftours/labs.ui-components';
import { Contracts } from '@eftours/tokenization-client-library';
import { useSelector } from 'react-redux';
import { validationErrorsViewModelSelector } from 'src/selectors';
interface IProps {
	errors: Partial<Contracts.ValidationResponse> | undefined;
	modifiedFields: Partial<Contracts.ModifiedFieldsResponse>;
}
export const ValidationErrors: React.FC<IProps> = ({ errors, modifiedFields }) => {
	const { copy } = useSelector(validationErrorsViewModelSelector);

	const errorsToRender: string[] = [];
	if (errors) {
		if (errors.card) {
			if (errors.card.cardNumber && modifiedFields.card?.cardNumber) {
				errorsToRender.push(copy.invalidCardNumber);
			}
			if (errors.card.cvNumber && modifiedFields.card?.cvNumber) {
				errorsToRender.push(copy.invalidCvv);
			}
			if (errors.card.binBase && modifiedFields.card?.binBase) {
				errorsToRender.push(copy.invalidBin);
			}
			if ((errors.card.expirationMonth && modifiedFields.card?.expirationMonth) || (errors.card.expirationYear && modifiedFields.card?.expirationYear)) {
				errorsToRender.push(copy.invalidExpirationDate);
			}
		}
		if (errors.checkingAccount) {
			if (modifiedFields.checkingAccount?.type === 'AmericanCheckingAccount') {
				const checkingAccount: Contracts.ValidationModel<Contracts.AmericanCheckingAccount> = errors.checkingAccount;
				const modifiedFieldsCheck = modifiedFields.checkingAccount;
				if (checkingAccount.accountNumber && modifiedFieldsCheck.accountNumber) {
					errorsToRender.push(copy.invalidAccountNumber);
				}
				if (checkingAccount.routingNumber && modifiedFieldsCheck.routingNumber) {
					errorsToRender.push(copy.invalidRoutingNumber);
				}
			} else if (modifiedFields.checkingAccount?.type === 'CanadianCheckingAccount') {
				const checkingAccount: Contracts.ValidationModel<Contracts.CanadadianCheckingAccount> = errors.checkingAccount;
				const modifiedFieldsCheck = modifiedFields.checkingAccount;
				if (checkingAccount.accountNumber && modifiedFieldsCheck) {
					errorsToRender.push(copy.invalidAccountNumber);
				}
				if (checkingAccount.institutionNumber && modifiedFieldsCheck.institutionNumber) {
					errorsToRender.push(copy.invalidInstitutionNumber);
				}
				if (checkingAccount.transitNumber && modifiedFieldsCheck.transitNumber) {
					errorsToRender.push(copy.invalidTransitNumber);
				}
			}
		}
		if (errors.billingAddress && modifiedFields.billingAddress) {
			const address = errors.billingAddress;
			const modifiedAddressFields = modifiedFields.billingAddress;

			if (address.city && modifiedAddressFields.city) {
				errorsToRender.push(copy.invalidCity);
			}
			if (address.firstName && modifiedAddressFields.firstName) {
				errorsToRender.push(copy.invalidFirstName);
			}
			if (address.lastName && modifiedAddressFields.lastName) {
				errorsToRender.push(copy.invalidLastName);
			}
			if (address.postalCode && modifiedAddressFields.postalCode) {
				errorsToRender.push(copy.invalidPostalCode);
			}
			if (address.street1 && modifiedAddressFields.street1) {
				errorsToRender.push(copy.invalidStreet);
			}
		}
		if (errorsToRender.length > 0) {
			return (
				<>
					<ValidationMessage inline messageType="error">
						{errorsToRender.map((value, index) => (
							<div key={index}>{value}</div>
						))}
					</ValidationMessage>
				</>
			);
		}
	}
	return null;
};
