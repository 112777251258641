import { Guid } from 'guid-typescript';

export const get = <T>(url: string, correlationId: Guid | null) => {
	return new Promise<T>((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				try {
					if (xhr.status === 200 || xhr.status === 201 || xhr.status === 202) {
						resolve(JSON.parse(xhr.responseText));
					} else {
						reject();
					}
				} catch (e) {
					reject();
				}
			}
		};
		xhr.ontimeout = () => {
			reject('timeout');
		};
		xhr.open('GET', url, true);
		xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
		xhr.setRequestHeader('X-Correlation-ID', `${correlationId}`);
		xhr.setRequestHeader('Request-Id', `${correlationId}`);
		xhr.send();
	});
};

export const post = <T, G>(url: string, correlationId: Guid | null, payload: T): Promise<G> => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200 || xhr.status === 201 || xhr.status === 202) {
					resolve(JSON.parse(xhr.responseText));
				} else {
					reject(JSON.parse(xhr.responseText));
				}
			}
		};
		xhr.ontimeout = () => {
			reject('timeout');
		};
		xhr.open('POST', url, true);
		xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
		xhr.setRequestHeader('X-Correlation-ID', `${correlationId}`);
		xhr.setRequestHeader('Request-Id', `${correlationId}`);
		xhr.send(JSON.stringify(payload));
	});
};

export const put = <T, G>(url: string, correlationId: Guid | null, payload: T): Promise<G> => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200 || xhr.status === 201 || xhr.status === 202) {
					if (xhr.responseText !== '') {
						resolve(JSON.parse(xhr.responseText));
					}
				} else {
					reject(false);
				}
			}
		};
		xhr.ontimeout = () => {
			reject('timeout');
		};
		xhr.open('PUT', url, true);
		xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
		xhr.setRequestHeader('X-Correlation-ID', `${correlationId}`);
		xhr.setRequestHeader('Request-Id', `${correlationId}`);
		xhr.send(JSON.stringify(payload));
	});
};
