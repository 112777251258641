import { put } from './httpFacade';
import { Guid } from 'guid-typescript';
import * as NavigationStateService from './navigationStateService';
import { RollbarLogger } from '../RollbarLogger';
export const logError = async (correlationId: Guid, message: string, errorCode: string): Promise<null> => {
	window.console.warn(message);

	const sessionStore = NavigationStateService.getSessionStore(correlationId);
	if (sessionStore) {
		sessionStore.errorCode = errorCode;
		NavigationStateService.setSessionStore(correlationId, sessionStore);
	}

	const errorLoggingRequest = {
		SessionStore: sessionStore,
		UrlPathName: window.location.pathname,
		UrlSearchParams: window.location.search,
		ErrorMessage: message,
		TimeZoneOffset: new Date().getTimezoneOffset(),
	};

	RollbarLogger.error(message, {
		correlationId,
		errorCode,
		warningContext: errorLoggingRequest,
	});

	const getUrl = window.location.protocol + '//' + window.location.host + '/api/logging/error';
	await put(getUrl, correlationId, errorLoggingRequest)
		.then((resolved: any) => resolved)
		.catch(() => null);

	return null;
};

export const logWarning = async (correlationId: Guid, message: string): Promise<null> => {
	window.console.warn(message);

	const sessionStore = NavigationStateService.getSessionStore(correlationId);
	const errorLoggingRequest = {
		SessionStore: sessionStore,
		UrlPathName: window.location.pathname,
		UrlSearchParams: window.location.search,
		ErrorMessage: message,
		TimeZoneOffset: new Date().getTimezoneOffset(),
	};

	RollbarLogger.warn(message, {
		correlationId,
		errorContext: errorLoggingRequest,
	});

	const getUrl = window.location.protocol + '//' + window.location.host + '/api/logging/warning';
	await put(getUrl, correlationId, errorLoggingRequest)
		.then((resolved: any) => resolved)
		.catch(() => null);

	return null;
};
