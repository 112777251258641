import StoryblokClient from 'storyblok-js-client';
import { Language } from '../models/copy';
interface IStoryBlokSpace {
	name: string;
	domain: string;
	version: number;
}

interface IStoryBlokSpaceResponse {
	space: IStoryBlokSpace;
}

export class StoryblokService {
	public devMode: boolean;
	public token: string | undefined;
	public client: StoryblokClient | undefined;
	public query: any;

	private cacheInitialized = false;

	constructor(token: string | undefined) {
		this.devMode = false; // If true it always loads draft
		this.query = {};

		if (token && token !== '') {
			this.setAccessToken(token);
		}
	}

	public setAccessToken(token: string) {
		this.token = token;
		this.client = new StoryblokClient({
			accessToken: this.token,
			cache: {
				clear: 'manual',
				type: 'memory',
			},
		});
	}
	public async initializeCache() {
		if (this.client) {
			if (!this.cacheInitialized) {
				return this.client
					.get('cdn/spaces/me', {})
					.then((response) => {
						const spaceResponse: IStoryBlokSpaceResponse = response.data;
						this.setCacheVersion(spaceResponse.space.version);
						this.cacheInitialized = true;
					})
					.catch((error: any) => {
						console.error('Failed to fetch CMS metadata for payments', error);
					});
			}
		} else {
			console.error('Client must be initialized before cache');
		}
	}

	public getCacheVersion() {
		return this.client?.cacheVersion;
	}

	public setCacheVersion(version: number) {
		if (this.client && version !== this.client.cacheVersion) {
			this.client.flushCache();
			this.client.cacheVersion = version;
			console.log('Using cache version: ', version);
		}
	}

	public getVersion = (): 'published' | 'draft' | undefined => {
		if (this.getQuery('_storyblok') || this.devMode || (typeof window !== 'undefined' && window.storyblok)) {
			return 'draft';
		}
		return 'published';
	};

	/**
	 * Gets full storyblok tree for page, resolves all sub items
	 * @param slug Relative url
	 * @param params Storyblok params
	 */
	public async get(slug: string, langaugeCode: string, params: any) {
		if (this.client) {
			params = params || {};
			params.version = this.getVersion();
			params.cv = this.getCacheVersion();
			window.console.log('version ' + params.version);
			window.console.log('cv ' + params.cv);
			const urlLanguage = langaugeCode !== Language.english ? `/${langaugeCode}` : '';

			return this.client.get(`cdn/stories${urlLanguage}/${slug}`, {
				...params,
			});
		}
		return undefined;
	}

	public setQuery(query: any) {
		this.query = query;
	}

	public getQuery(param: string) {
		return this.query[param];
	}
}
