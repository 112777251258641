import { IStoreState } from 'src/models';

export const validationErrorsViewModelSelector = ({ page }: IStoreState) => {
	return {
		copy: {
			reviewButton: page.copy.buttons.review,
			...page.copy.tokenizerValidation,
		},
	};
};
