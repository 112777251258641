import React, { useState, useCallback } from 'react';
import { Loader, Button, ValidationMessage } from '@eftours/labs.ui-components';
import { TokenizationClientFrame, Contracts, useTokenizationClient } from '@eftours/tokenization-client-library';
import { BillingAddressSelect } from './../BillingAddressSelect/BillingAddressSelect';
import { useSelector } from 'react-redux';
import { PreAuthorizedDebit } from './PreAuthorizedDebit';
import { ValidationErrors } from './ValidationErrors';
import { savePaymentMethod } from 'src/PageActions';
import { useThunkDispatch } from 'src/redux-thunk-adapter';
import { navigateToReview } from 'src/navigation';
import { useHistory } from 'react-router-dom';
import { validationErrorsViewModelSelector } from 'src/selectors';
import { RollbarLogger } from '../../RollbarLogger';

export const IFrameHost: React.FC<{
	isPadRequired: boolean;
	billingAddress: Contracts.RequiredBillingAddressFields;
	correlationId: string | undefined;
	anonymize: boolean;
}> = ({ isPadRequired, billingAddress, correlationId, anonymize }) => {
	const [validationErrors, setValidationErrors] = useState<Partial<Contracts.ValidationResponse> | undefined>({});
	const [modifiedFields, setModifiedFields] = useState<Partial<Contracts.ModifiedFieldsResponse>>({});

	const [hasAgreedToPAD, setHasAgreedToPAD] = useState(false);
	const [isCheckingAccountForm, setIsCheckingAccountForm] = useState(false);
	const { submit, updateFrame } = useTokenizationClient();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [didTokenizationFail, setDidTokenizationFail] = useState(false);

	const dispatch = useThunkDispatch();
	const history = useHistory();

	const { copy } = useSelector(validationErrorsViewModelSelector);

	const isPadValid = () => {
		if (isPadRequired && isCheckingAccountForm) {
			return hasAgreedToPAD;
		} else {
			return true;
		}
	};

	const onTokenRecieved = useCallback(
		async (tokenData: Contracts.TokenizationResponse) => {
			if (tokenData.wasSuccessful) {
				setDidTokenizationFail(false);
				await dispatch(savePaymentMethod(tokenData.signedToken));
				RollbarLogger.info('Tokenization succeeded', { correlationId, tokenData });
				navigateToReview({ history, correlationId });
			} else {
				RollbarLogger.error('Tokenization failed', { correlationId, tokenData });
				setIsSubmitting(false);
				setDidTokenizationFail(true);
				setIsSubmitting(false);
			}
		},
		[setDidTokenizationFail, setIsSubmitting, RollbarLogger]
	);

	const onStateReceived = useCallback(
		(state: Contracts.StateResponse) => {
			setIsSubmitting(false);
			if (state) {
				setValidationErrors(state.invalidFormFields);
				setModifiedFields(state.modifiedFields);
				setIsCheckingAccountForm(state.formState.activePaymentMethodType === Contracts.PaymentMethodType.CHECK);
			}
		},
		[setValidationErrors, setIsCheckingAccountForm, setModifiedFields]
	);

	const onErrorReceived = useCallback(
		(error: Contracts.ErrorMessage) => {
			RollbarLogger.critical('Tokenizer is misconfigured', { correlationId, error });
		},
		[RollbarLogger]
	);

	const submitWrapper = () => {
		setIsSubmitting(true);
		submit();
	};

	return (
		<div className="flex column fill">
			<div className="tokenizer">
				<TokenizationClientFrame
					data-test="tokenizer-frame"
					iFrameDisabledMessage="Oops"
					onTokenReceived={onTokenRecieved}
					onStateChangeReceived={onStateReceived}
					onErrorReceived={onErrorReceived}>
					<Loader />
				</TokenizationClientFrame>
			</div>
			<div>
				<BillingAddressSelect
					validation={validationErrors && validationErrors.billingAddress}
					updateBillingAddressInFrame={updateFrame}
					billingAddress={billingAddress}
					anonymize={anonymize}
				/>
			</div>
			<div>
				<ValidationErrors errors={validationErrors} modifiedFields={modifiedFields} />
			</div>

			{isPadRequired && isCheckingAccountForm ? <PreAuthorizedDebit hasAgreed={hasAgreedToPAD} setHasAgreed={setHasAgreedToPAD} /> : null}

			{didTokenizationFail && (
				<ValidationMessage inline messageType="error">
					{copy.tokenizationFailed}
				</ValidationMessage>
			)}
			<div className="hr"></div>
			<div className="flex center-content m-left-1_5 m-right-1_5 m-top-2 m-bottom-2">
				<Button variant="primary" data-test="submitBtn" disabled={!!validationErrors || isSubmitting || !isPadValid()} onClick={submitWrapper}>
					{isSubmitting ? <Loader /> : copy.reviewButton}
				</Button>
			</div>
		</div>
	);
};
