import * as React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { PaymentAmountEntryPage } from './PaymentAmountEntryPage';
import { FormWrapper, FlexWrapperEdges, Loader, ProductAppHeader, ProductAppFooter, TypographySkeleton } from '@eftours/labs.ui-components';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'src/models';
import { useEffect } from 'react';
import { loadPageData } from 'src/PageActions';
import { ReviewAndConfirm } from './ReviewAndConfirm/ReviewAndConfirm';
import { SuccessPage } from './SuccessPage';
import { ErrorPage } from './ErrorPage';
import { errorViewModel, LayoutViewModelSelector } from 'src/selectors';
import { ProductContentProvider } from '@eftours/labs.ui-components';
import { PaymentEntryPage } from './PaymentEntry';
import { SecureCheckoutLabel } from './SecureCheckoutLabel';
import { ReturnToTravelDetailsButton } from './ReturnToTravelDetailsButton';

export const Layout: React.FC = () => {
	const isSpinnerUp = useSelector<{ page: IState }, boolean>((x) => x.page.isSpinnerUp);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadPageData);
	}, []);
	const { businessGroup, language } = useSelector(LayoutViewModelSelector);

	return (
		<div className="content">
			<ProductContentProvider
				businessGroup={businessGroup}
				languageCode={language}
				storyblokApiKey={window.clientRuntimeConfig.storyblokSharedContentToken}>
				<div className="header">
					<ProductAppHeader leftContent={<TypographySkeleton isActive={true} width="sm" />} haveQuestionLabel="" supportPhoneNumber="" />
					<ReturnToTravelDetailsButton />
				</div>
				<FlexWrapperEdges>
					<FormWrapper>
						{isSpinnerUp ? (
							<div className="p-4 p-top-0">
								<Loader />
							</div>
						) : (
							<Router />
						)}
					</FormWrapper>
				</FlexWrapperEdges>
				<ProductAppFooter copyright="" legalNoticeLink="" legalNoticeLinkLabel="" />
			</ProductContentProvider>
		</div>
	);
};

const CheckError: React.FC = ({ children }) => {
	const { isError, correlationId } = useSelector(errorViewModel);

	const history = useHistory();

	if (isError && history.location.pathname !== '/error') {
		history.push(`/error?c=${correlationId}`);
		return (
			<div className="p-4 p-top-0">
				<Loader />
			</div>
		);
	} else {
		return children ? (
			<>{children}</>
		) : (
			<div className="p-4 p-top-0">
				<Loader />
			</div>
		);
	}
};

export const Router: React.FC = () => {
	return (
		<>
			<SecureCheckoutLabel />
			<Switch>
				<Route path="/" exact={true}>
					<CheckError>
						<PaymentAmountEntryPage />
					</CheckError>
				</Route>
				<Route path="/amount" exact={true}>
					<CheckError>
						<PaymentAmountEntryPage />
					</CheckError>
				</Route>
				<Route path="/payment-entry" exact={true}>
					<CheckError>
						<PaymentEntryPage />
					</CheckError>
				</Route>
				<Route path="/review" exact={true}>
					<CheckError>
						<ReviewAndConfirm />
					</CheckError>
				</Route>
				<Route path="/success" exact={true}>
					<CheckError>
						<SuccessPage />
					</CheckError>
				</Route>
				<Route path="/error" exact={true}>
					<ErrorPage />
				</Route>
			</Switch>
		</>
	);
};
