import { IBillingAddress } from './customer';

export enum CardBrand {
	None = 'Unknown',
	Visa = 'VISA',
	MasterCard = 'MASTERCARD',
}

export enum CardType {
	Debit = 'Debit',
	Credit = 'Credit',
}

export interface IPaymentMethod {
	paymentMethodId: number | null;
	isCredit: boolean;
	isChecking: boolean;
	isExpired: boolean;
	billingAddress: IBillingAddress;
	cardBrand: CardBrand;
	cardType: CardType;
	maskedAccountSummary: string;
	maskedAccountPrefix: string;
	maskedAccountSuffix: string;
	expirationDate: string;
	billingFirstName: string;
	billingLastName: string;
	currencyCode: string;
	signedPaymentPayload: string;

	isSuccessful: boolean;
	siteErrorCode: string;
	errorMessages: [string];
}
