import * as React from 'react';
import { Button, Collapsible } from '@eftours/labs.ui-components';
import { PaymentAmountEntryBox } from './PaymentAmountEntryBox';
import { useSelector, useDispatch } from 'react-redux';
import { paymentAmountEntryViewModel } from 'src/selectors';
import { setHasExpandedPaymentEntry } from 'src/PageActions';
import { PaymentEntryPage } from './PaymentEntry';
import { navigateToReview } from 'src/navigation';
import { useHistory } from 'react-router-dom';

export const PaymentAmountEntryPage: React.FC = () => {
	const { isExpanded, canContinue, copy, paymentMethod, correlationId } = useSelector(paymentAmountEntryViewModel);
	const dispatch = useDispatch();
	const history = useHistory();

	const setExpanded = async (isExpand: boolean) => {
		dispatch(setHasExpandedPaymentEntry(isExpand));
	};

	const renderContinueButton = () => {
		if (paymentMethod) {
			return (
				<>
					<Button
						disabled={!canContinue}
						data-test="review-btn"
						onClick={(_e) =>
							navigateToReview({
								history,
								correlationId: correlationId.toString(),
							})
						}
						variant="black">
						{copy.reviewButton}
					</Button>
				</>
			);
		} else {
			if (!isExpanded) {
				return (
					<>
						<Button data-test="add-payment-method-btn" disabled={!canContinue} onClick={(_e) => setExpanded(true)} variant="black">
							{copy.addPaymentMethodLabel}
						</Button>
					</>
				);
			}
		}
		return undefined;
	};

	return (
		<>
			<PaymentAmountEntryBox />

			<div className="flex column center-content soft-border top p-2 m-top-2">
				<div className="flex center-content">{renderContinueButton()}</div>
			</div>
			<Collapsible id="payment-entry-expander" data-test="payment-entry-expander" noBorder open={isExpanded}>
				<PaymentEntryPage />
			</Collapsible>
		</>
	);
};
