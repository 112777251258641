import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Reducer, combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { LayoutContainer } from './components/LayoutContainer';
import './style/index.scss';
import { IState, defaultState } from './models';
import PageReducer from './PageReducer';
import ReduxThunk from 'redux-thunk';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const combinedReducer = combineReducers({
	page: PageReducer as Reducer<IState>,
});

// The import for composeWithDevTools from `redux-devtools-extension/developmentOnly`
// makes it so that dev tools are only active when `process.env.NODE_ENV !== 'production'`
const composeEnhancers = composeWithDevTools({ trace: true });
const reduxStore = createStore(combinedReducer, { page: defaultState }, composeEnhancers(applyMiddleware(ReduxThunk)));

const history = createBrowserHistory();
ReactDOM.render(
	<Provider store={reduxStore}>
		<Router history={history}>
			<React.Fragment>
				<LayoutContainer />
			</React.Fragment>
		</Router>
	</Provider>,
	document.getElementById('root') as HTMLElement
);
