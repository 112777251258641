import { get, post } from './httpFacade';
import { ICustomer, IPaymentMethod, CardType, ITransactionResponse } from '../models';
import { Guid } from 'guid-typescript';
import { CardBrand } from 'src/models/paymentMethod';
import { BusinessGroup } from '../models/BusinessGroup';

interface CustomerDataResponse {
	restrictedPaymentTypes: number;
	balance: number;
	firstName: string;
	lastName: string | null;
	billingEmail: string | null;
	individualAddress: IndividualAddress | null;
	productMarket: string;
	businessUnit: string;
	daysPriorToDeparture: number;
	daysSinceEnrollment: number;
	links: {
		paymentsEntryUrl: string;
	};
	supportPhoneNumber: string;
	businessGroup: string;
	currencyCode: string;
	anonymize: boolean;
}

interface IndividualAddress {
	firstName: string;
	lastName: string;
	street1: string;
	city: string;
	state: string;
	country: string;
	postalCode: string;
}

export const getCustomerData = async (
	urlParams: string,
	correlationId: Guid | null,
	language: string | null,
	useAlbRoute: boolean,
	albRoute: string
): Promise<ICustomer | null> => {
	const getUrl = useAlbRoute
		? window.location.protocol + '//' + window.location.host + albRoute + '/api/Customers/Customer?data=' + urlParams + '&correlationId=' + correlationId
		: window.location.protocol + '//' + window.location.host + '/api/Customers/Customer?data=' + urlParams + '&correlationId=' + correlationId;
	const response = await get<CustomerDataResponse>(getUrl, correlationId);
	if (response) {
		const paymentEntryUrl = () => {
			return response.links.paymentsEntryUrl;
		};

		const defaultBillingAddress = {
			city: '',
			country: response.businessGroup === BusinessGroup.etca ? 'CA' : 'US',
			firstName: '',
			lastName: '',
			postalCode: '',
			state: '',
			street1: '',
		};

		// if we got an anonymized response, use the default billing address above.
		const address = !response.anonymize && response.individualAddress ? response.individualAddress : defaultBillingAddress;

		return {
			anonymize: response.anonymize,
			firstName: response.firstName,
			lastName: !response.anonymize && response.lastName ? response.lastName : '',
			enrolledDays: response.daysSinceEnrollment,
			enterPaymentMethodUrl: paymentEntryUrl(),
			amountDue: response.balance,
			supportPhoneNumber: response.supportPhoneNumber,
			businessGroup: response.businessGroup as BusinessGroup,
			currencyCode: response.currencyCode,
			businessUnit: response.businessUnit,
			productMarket: response.productMarket,
			restrictedPaymentTypes: response.restrictedPaymentTypes,
			billingAddress: {
				city: address.city,
				country: address.country,
				firstName: address.firstName,
				lastName: address.lastName,
				postalCode: address.postalCode,
				state: address.state,
				street1: address.street1,
			},
		};
	}

	return null;
};

export const savePaymentMethod = async (
	customerPayload: string,
	signedPaymentInfo: string,
	correlationId: Guid | null,
	useAlbRoute: boolean,
	albRoute: string
): Promise<IPaymentMethod | null> => {
	const payload = {
		customerParamsPayload: customerPayload,
		paymentInfoPayload: signedPaymentInfo,
		correlationId: correlationId?.toString(),
	};
	const url = useAlbRoute
		? window.location.protocol + '//' + window.location.host + albRoute + '/api/payments/tokens'
		: window.location.protocol + '//' + window.location.host + '/api/payments/tokens';
	const response: any = await post(url, correlationId, payload)
		.then(
			(resolved: any) => resolved,
			(rejected: any) => rejected
		)
		.catch(() => null);

	if (response) {
		return <IPaymentMethod>{
			paymentMethodId: response.paymentMethodId,
			isCredit: response.isCredit,
			isChecking: response.isChecking,
			isExpired: response.isExpired,
			billingAddress: response.billingAddress,
			cardBrand: response.cardBrand as CardBrand,
			cardType: response.cardType as CardType,
			maskedAccountSummary: response.maskedAccountSummary,
			maskedAccountPrefix: response.maskedAccountPrefix,
			maskedAccountSuffix: response.maskedAccountSuffix,
			expirationDate: response.expirationDate,
			billingFirstName: response.billingFirstName,
			billingLastName: response.billingLastName,
			currencyCode: response.currencyCode,
			signedPaymentPayload: response.signedPaymentPayload,
			isSuccessful: response.isSuccessful,
			siteErrorCode: response.siteErrorCode,
			errorMessages: response.errorMessages,
		};
	}

	return null;
};

export const postMakePayment = async (
	customerPayload: string,
	paymentMethod: IPaymentMethod,
	amount: number,
	correlationId: Guid | null,
	useAlbRoute: boolean,
	albRoute: string
): Promise<ITransactionResponse | null> => {
	const url = useAlbRoute
		? window.location.protocol + '//' + window.location.host + albRoute + '/api/payments/transactions'
		: window.location.protocol + '//' + window.location.host + '/api/payments/transactions';

	const payload = {
		correlationId: correlationId?.toString(),
		amount,
		paymentMethodSignedPayload: paymentMethod.signedPaymentPayload,
		customerParamsPayload: customerPayload,
	};

	const response: any = await post(url, correlationId, payload)
		.then(
			(resolved: any) => resolved,
			(rejected: any) => rejected
		)
		.catch(() => null);

	return response;
};
