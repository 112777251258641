import * as React from 'react';
import { Typography, Button } from '@eftours/labs.ui-components';
import { useSelector } from 'react-redux';
import { successPageViewModel } from 'src/selectors/SuccessPage.selectors';
import { navigateToSecureSite } from 'src/navigation';

export const SuccessPage: React.FC = () => {
	const { paymentAmount, correlationId, balanceAfterPayment, copy } = useSelector(successPageViewModel);

	return (
		<>
			<div className="form-wrapper-padding">
				<Typography variant="header2">{copy.title}</Typography>
				<Typography variant="body1">{copy.message}</Typography>

				<div className="transaction-summary-grid">
					<div className="row">
						<div className="cell side-by-side">
							<Typography className="font-grey m-bottom-0" variant="body1">
								{copy.chargedToday}
							</Typography>
							<Typography variant="header3" className="m-bottom-0_5" data-test="amount-paid">
								{paymentAmount}
							</Typography>
						</div>
						<div className="cell">
							<Typography className="font-grey m-bottom-0" variant="body1">
								{copy.updatedBalance}
							</Typography>
							<Typography data-test="updated-balance" variant="header3" className="m-bottom-0_5">
								{balanceAfterPayment}
							</Typography>
						</div>
					</div>
					<div className="row">
						<div className="cell">
							<Typography variant="uilabel-bold" data-test="confirmation-message">
								{copy.confirmationMessage}
							</Typography>
						</div>
					</div>
				</div>
				<div className="flex center-content m-bottom-3">
					<Button variant="secondary" data-test="navigate-to-secure-site-btn" onClick={() => navigateToSecureSite({ correlationId })}>
						{copy.travelDetailsButton}
					</Button>
				</div>
			</div>
		</>
	);
};
