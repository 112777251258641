import { IStoreState } from 'src/models';
import { BusinessGroup } from '@eftours/labs.ui-components';

export const LayoutViewModelSelector = ({ page }: IStoreState) => {
	let businessGroup = BusinessGroup.UNKNOWN;
	switch (page.customer.businessGroup) {
		case 'ETUS':
			businessGroup = BusinessGroup.ETUS;
			break;
		case 'ETCA':
			businessGroup = BusinessGroup.ETCA;
			break;
		case 'EAUS':
			businessGroup = BusinessGroup.EAUS;
			break;
		case 'COUS':
			businessGroup = BusinessGroup.COUS;
			break;
		case 'CST':
			businessGroup = BusinessGroup.CST;
			break;
		case 'GST':
			businessGroup = BusinessGroup.ETUS; // Missing
			break;
	}

	return {
		businessGroup: businessGroup,
		language: page.languageCode,
	};
};
