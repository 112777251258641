import { IStoreState, Language } from 'src/models';
import { Contracts } from '@eftours/tokenization-client-library';
import { getCurrencyFormatter, getBalanceAfterPayment, replaceTokens } from '.';
import { SupportedLocales } from '@eftours/labs.ui-components';

export const paymentAmountEntryViewModel = ({ page }: IStoreState) => {
	const balanceAfterPayment = getBalanceAfterPayment(page);
	const paymentAmount = page.paymentAmount ?? 0;
	return {
		correlationId: page.sessionKey,
		// This is weird, we are using a field from redux and the balance to decide
		// if the page is expanded. Feels very wrong.
		isExpanded: !page.isPaymentAmountEditable || (page.hasExpandedPaymentEntry && balanceAfterPayment >= 0),
		canContinue: balanceAfterPayment >= 0 && paymentAmount > 0,
		paymentMethod: page.paymentMethod,
		copy: {
			reviewButton: page.copy.buttons.review,
			addPaymentMethodLabel: page.copy.paymentMethod.addPaymentMethodLabel,
		},
	};
};

export const paymentAmountEntryBoxViewModel = ({ page }: IStoreState) => {
	const formatter = getCurrencyFormatter(page);
	const balanceAfterPayment = getBalanceAfterPayment(page);
	return {
		languageCode: page.languageCode,
		locale: getComponentLibLocale(page.languageCode),
		balance: formatter.format(page.customer.amountDue),
		balanceAfterPayment: formatter.format(balanceAfterPayment),
		paymentAmount: page.paymentAmount,
		shouldWarnAboutOverpaying: balanceAfterPayment < 0,
		isPaymentAmountEditable: page.isPaymentAmountEditable,
		copy: {
			header: page.copy.paymentAmount.header,
			subheader: replaceTokens(page.copy.paymentAmount.subheader, {
				traveler_name: page.customer.firstName,
				balance_amount: formatter.format(page.customer.amountDue),
			}),
			currentBalance: page.copy.balance.currentLabel,
			newBalance: page.copy.balance.newLabel,
			paymentAmountLabel: page.copy.paymentAmount.paymentAmount,
			payFullBalanceButton: page.copy.buttons.payFullBalance,
			cannotPayMoreThanBalanceWarning: page.copy.error.codes.amountLargerThanBalance,
		},
	};
};

const getComponentLibLocale = (language: Language) => {
	switch (language) {
		case 'en-CA':
			return SupportedLocales.englishCanada;
		case 'fr-CA':
			return SupportedLocales.frenchCanada;
		case 'en-US':
		default:
			return SupportedLocales.englishUnitedStates;
	}
};

export const paymentEntryFormViewModel = ({ page }: IStoreState) => {
	return {
		anonymize: page.customer.anonymize,
		businessGroup: page.customer.businessGroup,
		tokenizerUrl: page.customer.enterPaymentMethodUrl,
		billingAddress: page.customer.billingAddress,
		initialFrameSettings: {
			settings: {
				correlationId: page.sessionKey.toString(),
				businessUnit: page.customer.businessUnit,
				productMarket: page.customer.productMarket,
				languageCode: page.languageCode,
				appCode: 'PAYMENTS-CUSTOMER-SITE',
				paymentMethodRestrictions: page.customer.restrictedPaymentTypes,
			},
			prefillFormFields: {
				firstName: page.customer.billingAddress?.firstName,
				lastName: page.customer.billingAddress?.lastName,
			},
			billingAddress: { ...page.customer.billingAddress },
		} as Contracts.FrameInitializationRequest,
		copy: {
			addPaymentMethodLabel: page.copy.paymentMethod.addPaymentMethodLabel,
		},
	};
};

export const billingAddressSelector = ({ page }: IStoreState) => {
	return {
		language: page.languageCode,
		copy: {
			...page.copy.address,
		},
	};
};
