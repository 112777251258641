import { Typography, Checkbox } from '@eftours/labs.ui-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { preAuthorizedDebitViewModelSelector } from 'src/selectors';

export const PreAuthorizedDebit: React.FC<{ hasAgreed: boolean; setHasAgreed: (val: boolean) => void }> = ({ hasAgreed, setHasAgreed }) => {
	const { copy } = useSelector(preAuthorizedDebitViewModelSelector);

	return (
		<>
			<div data-test="canadian-pad">
				<Typography className="m-bottom-0_5" variant="header3">
					{copy.padLabel}
				</Typography>
				<Typography className="m-bottom-1" variant="caption">
					{copy.padInstructions}
				</Typography>
				<div data-test="canadian-pad-content" className="pad-legal">
					<Typography variant="body1">{copy.padAgreement1}</Typography>
					<Typography variant="body1">{copy.padAgreement2}</Typography>
					<Typography variant="body1">{copy.padAgreement3}</Typography>
					<Typography variant="body1">{copy.padAgreement4}</Typography>
					<Typography variant="body1">{copy.padAgreement5}</Typography>
				</div>
				<div className="m-top-1_5">
					<Checkbox
						onChange={(e) => setHasAgreed(e.currentTarget.checked)}
						data-test="canadian-pad-chk"
						id="pad-chk"
						name="pad-chk"
						value={hasAgreed ? 'true' : 'false'}
						checked={hasAgreed}>
						{copy.agreementText}
					</Checkbox>
				</div>
			</div>
		</>
	);
};
