import { Typography } from '@eftours/labs.ui-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from 'src/models';
import lockImage from '../images/lock_closed_black.svg';

export const SecureCheckoutLabel: React.FC = () => {
	const label = useSelector(({ page }: IStoreState) => page.copy.header.secureCheckoutLabel);

	return (
		<>
			<div className="secure-checkout-label">
				<div>
					<img src={lockImage} alt={`lock image`} className="inline-icon" />
				</div>
				<Typography variant="uilabel-light">{label}</Typography>
			</div>
		</>
	);
};
